import axios from '../libs/ajaxRequest';

/* 获取首页Banner图 */
const getBanners = () => {
    return axios.request({
        url: 'product/banner',
        method: 'get'
    });
};

/* 获取秒杀产品列表 */
const getflashsales = (data) => {
    return axios.request({
        url: `product/seckill/${data.appKey}/${data.index}/${data.pageSize}`,
        method: 'get'
    });
};

/* 获取实物商品列表 */
const getproducts = (data) => {
    return axios.request({
        url: `product/entity/${data.appKey}`,
        method: 'post',
        data
    });
};

export {
    getBanners,
    getflashsales,
    getproducts,
}
