<template>
    <div id="seckill">
        <div class="navbar">
            <button @click="returnPage"><i class="iconfont icon-fanhui1"></i>商品秒杀</button>
        </div>

        <div class="content">
            <van-skeleton class="cardLoading" :row="10" row-width="99%" :loading="cardLoading" :animate="false"></van-skeleton>
            <div v-if="!cardLoading">
                <div class="card" v-for="(item, index) in seckillProduct" :key="index" @click="jump('productdetails', item.productId)">
                    <img v-lazy="item.listImg"/>
                    <div>
                        <div>
                            <h4>{{item.productName}}</h4>
                            <p>{{item.endTime}}</p>
                        </div>
                        <div>
                            <p>
                                <span>￥{{item.secKillPrice}}+{{item.secKillIntegral}}良豆</span>
                            </p>

                            <div>
                                <h4>去抢购</h4>
                                <van-progress class="progress" :percentage="50" color="#FFF" track-color="#e59294" :show-pivot="false"  stroke-width="4"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <van-tabs v-model="active">
                 <van-tab v-for="index in 6" :key="index">
                    <template #title>
                        <div class="tabTitle">
                            <h4>14:00</h4>
                            <p :class="{'active': index-1 == active}">即将开始</p>
                        </div>
                    </template>
                    
                    
                    <div class="card" v-for="item in 20" :key="item">
                        <img src="@/image/seckill.jpg"/>
                        <div>
                            <div>
                                <h4>产品名称产品名称产品名称产品名称产品名称产品名称产品名称产品名称</h4>
                                <p>01:18:11</p>
                            </div>
                            <div>
                                <p>
                                    <span>￥359+1500良豆</span>
                                </p>

                                <div>
                                    <h4>去抢购</h4>
                                    <van-progress class="progress" :percentage="50" color="#FFF" track-color="#e59294" :show-pivot="false"  stroke-width="4"/>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                </van-tab>
            </van-tabs> -->
        </div>
    </div>
</template>

<script>
import {getflashsales} from '../../api/home'
export default {
    data () {
        return {
            active: 0,
            appKey: '6ade2634fb429b3d73d3f8793d33afcf',
            cardLoading: true,
            flashsaleData: { //秒杀产品入参
                appKey: '',
                index: 1,
                pageSize: 20
            },
            seckillProduct: [], //秒杀产品
        }
    },
    mounted () {
        const {appKey} = this.$route.query;
        // this.appKey = appKey;
        this.getSeckill();
    },
    methods: {
        /* 获取秒杀产品 */
        async getSeckill () {
            const {appKey, flashsaleData} = this;
            this.flashsaleData.appKey = appKey;
            let res = await getflashsales(flashsaleData);
            const {code, data, msg} = res;
            if (code == 1) {
                this.seckillProduct = data.list;
                this.cardLoading = false;
            } else {
                this.$toast(msg);
            }
        },

         /* 页面跳转 */
        jump (name, productId) {
            const {$router, $route} = this;
            const {appKey, credits, timestamp, sign, uid, code, state} = $route.query;
            $router.push({name, query:{appKey, credits, timestamp, sign, uid, productId, code, state}});
        },

        /* 返回上一步页面 */
        returnPage () {
            this.$router.go(-1);
        }
    } 
}
</script>

<style lang="less" src="@/style/kingkong/seckill.less">

</style>